<template>
  <div>
    <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title) + ` [ID: ${op_confirm.value}]`" size="lg">
      {{ $t(op_confirm.message) }}
      <template #footer>
        <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
        <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
        <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
      </template>
    </CModal>

    <CModal :show.sync="modalEmail.show" :centered="true" :title="$t('Email control')" size="lg">
      <template v-if="modalEmail.show">
        <ModalEmail :entity="modalEmail.entity" :recipients="modalEmail.recipients" :contact_types="modalEmail.contact_types" :subject_prefix="modalEmail.prefix" @modalUpdated="onModalEmailUpdated" @parentHide="modalEmail.show = false" />
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>

    <CCard>
      <CCardHeader v-if="!options.modal">
        <span v-if="!config.noTitle" class="font-2xl">{{ options.display_name || $t(_humanize(options.lname || `${options.name}s`)) }}</span>
        <!--ExcelGenerator :data="table_items" :fields="excel_fields" :worksheet="$t(_humanize(options.name))" :name="`${options.name}-full.xlsx`" /-->
        <CButton v-if="!config.noCreate" class="float-right" color="primary" @click="createItem">{{ $t(`Create ${options.name.replaceAll('_', ' ')}`) }}</CButton>
        <slot class="float-right mr-2" name="header-button" />

        <CButton class="float-right mr-2" color="secondary" @click="getData()"><CIcon name="cil-reload"/></CButton>
        <CButton v-if="modalEmail.button" type="button" class="float-right mr-2" color="danger" @click="onEmail"><CIcon name="cil-envelope-closed"/></CButton>
        <ExcelGenerator v-if="!config.noExcel && table_items" ref="excel_full" class="float-right" :data="table_items" :fields="excel_fields" :formulas="excel_formulas" :worksheet="options.display_name || $t(_humanize(options.name))" :name="`${options.file_name || options.name + 's-full'}.xlsx`">
          <span class="btn btn-success mr-2" :title="$t('Generate Excel file')"><CIcon name="cil-spreadsheet"/></span>
        </ExcelGenerator>
        <ExcelGenerator v-if="!config.noExcel && $refs.cdatatable && $refs.cdatatable.currentItems && $refs.cdatatable.currentItems.length && hasFilters" ref="excel_filtered" class="float-right" :data="$refs.cdatatable.columnFiltered" :fields="excel_fields" :formulas="excel_formulas" :worksheet="options.display_name || $t(_humanize(options.name))" :name="`${options.file_name || options.name + 's'}-filtered.xlsx`">
          <span class="btn btn-info mr-2" :title="$t('Generate filtered Excel file')"><CIcon name="cil-spreadsheet"/></span>
        </ExcelGenerator>
      </CCardHeader>

      <CCardBody>
        <CDataTable ref="cdatatable" striped hover v-bind="$attrs" v-on="$listeners" :items="table_items" :fields="table_fields" :columnFilter="true" :pagination="true" :sorter="true" :tableFilter="{ label: $t('Search'), placeholder: $t('Search') + '...' }" :items-per-page-select="{ label: $t('Items per page'), values: items_per_page_select }" :items-per-page="options.perPage" :active-page="options.activePage" @update:column-filter-value="onColumnFilterValue">
          <template v-if="fixed.filters" #over-table>
            <div class="row">
              <div v-for="filter of fixed.filters" :key="filter.key" class="col-sm-2">
                <AMultiSelect :ref="`ms-filter-${filter.key}`" :horizontal="{ label: 'col-sm-12', input: 'col-sm-12' }" :label="_humanize(filter.label)" v-model="filter_values[filter.key]" :options="filter.options || (filter.list ? lists[filter.list] : false) || filters[filter.key] || []" :config="filter.config" :allowEmpty="!filter.forced" :multiple="filter.multiple" :closeOnSelect="!filter.multiple" @input="event => applyFilter(event, filter.key, 'input')" @change="event => applyFilter(event, filter.key, 'change')" />
              </div>
              <div v-if="fixed.buttonCallback" class="col-sm-1">
                <div class="form-group form-row" role="group">
                  <label class="col-form-label col-sm-12">{{ $t('Consult') }}</label>
                  <CButton label="test" class="float-right mr-2" color="secondary" @click="fixed.buttonCallback">{{ $t('Consult') }}</CButton>
                </div>
              </div>
            </div>
          </template>

          <!--template #cleaner>
            <CIcon name="cil-filter-x" style="cursor: pointer;" class="ml-2" :class="{ 'text-danger': hasFilters, 'text-muted': !hasFilters }" @click.native="onCleanFilters" />
          </template-->

          <template v-if="config._check" #_check-header>
            <CInputCheckbox :checked.sync="checked" custom @click="toggleCheck" />
          </template>

          <template v-if="config._check" #_check="{item}">
            <td>
              <!-- // TODO: Add helper (popup - popover) with check and control+check -->
              <CInputCheckbox :ref="`_check_${item.id}`" color="primary" :checked="item.__checked" custom @click="onChecked(item)" />
            </td>
          </template>

          <template v-for="(field, f_index) of parsed_fields" #[field.key]="{item}">
            <slot :name="field.key" :item="item">
              <td :key="field.key" :class="field._classes || ''">
                <template v-if="field.icons">
                  <CIcon :name="field.icons[item[field.key]]" />
                </template>
                <template v-else-if="field.badge">
                  <CBadge :color="item.__badges[field.key]">{{ item[field.key] }}</CBadge>
                </template>
                <template v-else-if="field.link">
                  <a :href="item.__links[field.key]">{{ field.render ? field.render(item[field.key]) : item[field.key] }}</a>
                </template>
                <template v-else-if="field.array">
                  <span v-for="(subitem, index) of item[field.key]" :key="`${field.key}_${index}`">{{ field.render ? field.render(subitem) : subitem }}<br /></span>
                </template>
                <template v-else-if="field.select">
                  <AMultiSelect :ref="`ms-${field.key}-${f_index}`" v-model="item[field.key]" :options="field.select.options || []" :config="field.select.config" :allowEmpty="!field.select.forced" :multiple="field.select.multiple" :closeOnSelect="!field.select.multiple" @input="event => field.select.onChange(event, f_index, field.key, 'input')" @change="event => field.select.onChange(event, f_index, field.key, 'change')" />
                </template>
                <template v-else>
                  {{ field.render ? field.render(item[field.key]) : item[field.key] }}
                </template>
              </td>
            </slot>
          </template>

          <template v-if="!config.noOptions" #options="{item}">
            <td v-if="!options.modal" style="text-align: center; white-space: nowrap;">
              <template v-if="config.show === false">
                <slot name="show" :item="item" />
              </template>
              <template v-else>
                <CButton v-if="!config.modal && item.__editable" class="mr-2" color="secondary" @click="gotoOption(item)">{{ $t('Show') }}</CButton>
              </template>
              <template v-if="config.edit === false">
                <slot name="edit" :item="item" />
              </template>
              <template v-else>
                <CButton v-if="isEditable(item)" class="mr-2" color="primary" @click="gotoOption(item, '/edit')">{{ $t('Edit') }}</CButton>
              </template>
              <template v-if="config.delete === false">
                <slot name="delete" :item="item" />
              </template>
              <template v-else>
                <CButton v-if="isDeletable(item)" class="mr-2" color="danger" @click="deleteItem(item.id)">{{ $t('Delete') }}</CButton>
              </template>
              <template v-if="isDuplicable">
                <CButton color="info" @click="gotoOption(item, '/edit?duplicate=true', true)">{{ $t('Duplicate') }}</CButton>
              </template>
            </td>
            <td v-else style="text-align: center; white-space: nowrap;">
              <CButton v-if="!config.modal && item.__editable" class="mr-2" color="secondary" @click="gotoOption(item)">{{ $t('Show') }}</CButton>
              <CButton v-if="isEditable(item)" class="mr-2" color="primary" @click="gotoOption(item, '/edit')">{{ $t('Edit') }}</CButton>
              <CButton v-if="isDeletable(item)" class="mr-2" color="danger" @click="deleteItem(item.id)">{{ $t('Delete') }}</CButton>
            </td>
          </template>

          <template #footer="{itemsAmount}">
            <tfoot>
              <tr>
                <td :colspan="table_fields.length" style="whitespace: nowrap;">
                  {{ footerString.replace('XXX', itemsAmount) }}
                </td>
              </tr>
            </tfoot>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { get_attribute } from '@/app/_utils/global-utils'
import crudMixin from '@/app/views/_mixins/crud-mixin'

// TODO: IMPORTANT! Mix Fields (with helpers and renders) with Filters and Excel columns make it work with only one array !!!! URGENT !!!

export default {
  name: 'ACRUDTable',
  inheritAttrs: true,
  mixins: [crudMixin],
  watch: {
    items(val) {
      this.table_items = val
      console.log('update')
    },
    helpers(helpers) {
      this.view_helpers = { ...(helpers || {}), ...this.view_helpers }
    },
    fields(fields) {
      this.table_fields = this.parseFields([...fields], true) // TODO: find a way to do this once
      this.parsed_fields = this.parseFields(fields) // TODO: find a way to do this once
      this.setExcelFields()
    }
  },
  computed: {
    hasFilters() {
      return (this.$refs.cdatatable?.columnFiltered || []).length !== this.table_items.length
    },

    currentItems() {
      return this.$refs.cdatatable.currentItems
    },

    columnFiltered() {
      return this.$refs.cdatatable.columnFiltered
    },

    footerString() {
      if (this.hasFilters) return this.$t('Showing XXX of') + ` ${this.columnFiltered.length}` + ` (${(this.table_items || []).length} total)`
      return this.$t('Showing XXX of') + ` ${(this.table_items || []).length}`
    },

    isDuplicable() {
      if (this.config.duplicate && this.config.ignore_isDuplicable) return true
      return this.config.duplicate && this.periods._status === 'FAC' && (!this.latest || this.latest < this.periods.FAC.month_id)
    }
  },
  props: {
    items: {
      Array
    },
    fields: {
      type: Array,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    email: {
      type: Object
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    nextButtonHtml: 'next',
    previousButtonHtml: 'prev'
  },
  data() {
    return {
      fixed: {},
      latest: false,
      filters: {},
      checked: false,
      response: false,
      table_items: [],
      excel_fields: {},
      filter_names: [],
      filter_labels: {},
      filter_values: {},
      filter_objects: {},
      excel_formulas: {},
      modalEmail: { ...this.email },
      parsed_fields: this.parseFields(this.fields), // TODO: find a way to do this once
      table_fields: this.parseFields([...this.fields], true), // TODO: find a way to do this once
      options: { currentPage: 1, perPage: 50, totalRows: 0, ...this.config },
      op_confirm: {},
      op_confirm_delete: { show: false, title: 'Remove row', message: 'Are you sure you want to delete this row?', value: false, ...this.config.confirm }, // TODO: Use single with param ?
      op_confirm_duplicate: { show: false, title: 'Duplicate row', message: 'Are you sure you want to duplicate this row?', value: false, ...this.config.confirm }, // TODO: Use single with param ?
      items_per_page_select: []
    }
  },
  mounted() {
    this.getData(!!this.items)
  },
  methods: {
    // ParseData ---
    getData(preloaded, params, keep_filters, callback) {
      if (preloaded) {
        this.setOptions()
        this.drawItems(this.items)
      } else {
        const self = this
        if (!keep_filters) self.onCleanFilters()
        self.filters = {}
        self.latest = false
        self.$http
          .get(self.options.url, { ...(self.options.params || {}), ...(params || {}) }) // TODO: !IMPORTANT Use backend sorting and pagination ???
          .then(response => {
            this.response = response
            self.prepareHelpers(response.data._lists || [], true)
            self.drawItems(response.data[self.options.lname || `${self.options.name.replaceAll(' ', '_')}s`])
            if (keep_filters) self.applyFilters(this.filter_values)
            if (callback) callback()
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    parseItems(items) {
      this.parseFilters()
      items.map(item => this.parseItem(item))
      for (const filter in this.filters) {
        const filter_options = []
        new Set(this.filters[filter]).forEach(item => {
          //filter_options.push(typeof item === 'string' ? { id: item, name: item } : item)
          if (this.filter_objects[filter].use) filter_options.push({ id: item, name: this.filter_labels[filter][item] })
          else if (this.filter_objects[filter].use_object) filter_options.push({ id: item, name: this.filter_objects[filter].use_object[item] })
          else filter_options.push({ id: item, name: item })
        })

        this.filters[filter] = filter_options
      }
      return items
    },
    parseItem(item) {
      item._locked = item._locked || false
      item.__checked = false
      item.__filtered = false
      item.__editable = item.id && !item._locked
      item.__deletable = item.id && !item.is_principal
      this.setLatest(item)

      for (let field of this.fields) {
        field = typeof field === 'string' ? { key: field } : field
        let value = item[field.key || field]
        if (field.link) item.__links = { ...item.__links, [field.key]: field.link.href.replace('XXX', get_attribute(item, field.link.id)) } // TODO: render or parser ?
        if (field.badge) item.__badges = { ...item.__badges, [field.key]: field.badge.colors[item[field.helper.id || field.key]] } // TODO: render or parser ?  // Check 0 index
        if (field.helper) value = item[field.key] = field.helper.parser ? field.helper.parser(get_attribute(item, field.helper.id)) : get_attribute(item, field.helper.id)

        if (this.options.filters && this.filter_names.indexOf(field.key) >= 0) {
          this.filters[field.key] = [...(this.filters[field.key] || []), value]
          if (this.filter_objects[field.key].use) this.filter_labels[field.key] = { ...this.filter_labels[field.key], [value]: get_attribute(item, this.filter_objects[field.key].use) }
          /*if (this.filter_objects[field.key].use_object) {
            console.log(this.filter_objects[field.key])
            //this.filter_labels[field.key] = { ...this.filter_labels[field.key], [value]: get_attribute(item, this.filter_objects[field.key].use) }
          }*/
        }
      }
      return item
    },
    // TODO: find a better way
    parseFields(fields, extra_fields, for_excel) {
      const extra = { _style: 'width: 120px;' }
      const parsed = {}
      const parsed_fields = []
      const extra_width = 200 + (this.config.modal ? 0 : 50) + (this.config.duplicate ? 80 : 0)

      if (extra_fields && !for_excel) {
        fields = [
          //
          ...(this.config._check ? [{ key: '_check', sorter: false, filter: false, _style: 'width: 20px;' }] : []),
          ...(this.config._sorted ? [{ key: '_sorted', label: '|', sorter: true, filter: false }] : []),
          ...fields
        ]
        if (this.config.noOptions !== 'hide') fields.push({ key: 'options', label: ' ', sorter: false, filter: false, _style: `text-align: center; width: ${extra_width}px;` })
        fields.push({ key: '__filtered', sorter: false, filter: false, _classes: 'hide', _style: 'display: none;' })
      }

      for (let field in fields) {
        field = fields[field]
        if (field._hide !== true) {
          field = { ...(typeof field === 'string' ? { key: field } : field) }
          field.label = this.$t(field.label || this._humanize(field.key))
          if (field.key === 'id') field = { ...extra, ...field }
          if (field.select && !field.select.onChange) field.select.onChange = value => value
          // Avoid duplicates
          if (!parsed[field.key]) parsed_fields.push(field)
          parsed[field.key] = true
        }
      }
      return parsed_fields
    },
    drawItems(items) {
      this.table_items = this.parseItems(this.config.parseItems ? this.config.parseItems(this, items) : items)
      this.setOptions()
      this.dataChanged()
    },

    setLatest(item) {
      if (this.config.latest && (!this.latest || this.latest < item[this.config.latest])) this.latest = item[this.config.latest]
    },
    isLatest(item) {
      if (!this.config.latest) return true
      return item[this.config.latest] === this.latest
    },
    isEditable(item) {
      if (this.config.duplicate && !this.config.ignore_isDuplicable) return item.month_id >= this.periods.FAC.month_id
      return this.isLatest(item) && item.__editable
    },
    isDeletable(item) {
      return this.isEditable(item) && item.__deletable !== false
    },

    // Check ---
    areAllChecked(ctrlKey) {
      const type = ctrlKey ? 'columnFiltered' : 'currentItems'
      return this.currentItems.length > 0 && this.$refs.cdatatable[type].filter(item => !item.__checked).length === 0
    },
    toggleCheck(event) {
      const type = event.ctrlKey ? 'columnFiltered' : 'currentItems'
      const checked = !this.areAllChecked(event.ctrlKey)
      this.$refs.cdatatable[type].map(item => (item.__checked = checked))
      this.dataChanged()
      setTimeout(() => (this.checked = checked), 2)
    },
    getChecked() {
      return this.table_items.filter(item => item.__checked)
    },
    onChecked(item) {
      item.__checked = !item.__checked
      this.dataChanged()
    },

    // Filters ---
    parseFilters() {
      this.filter_names = []
      for (let filter of this.options.filters || []) {
        if (typeof filter === 'string') filter = { key: filter, label: filter, multiple: true }
        this.filter_names.push(filter.key)
        this.filter_objects[filter.key] = filter
      }
    },
    applyFilter(value, key, type) {
      //const filter = this.config.filters.find(filter => filter.key === key)
      const filter = this.fixed.filters.find(filter => filter.key === key)
      if (filter && filter.callback) {
        filter.callback(value)
      } else {
        if (!Array.isArray(value)) value = value !== undefined && value !== null ? [value] : []
        this.onColumnFilterValue({ [key]: value.length ? value : '' })
        this.applyFilters(this.filter_values, type)
      }
    },
    applyFilters(filter_values, type) {
      let value = 0
      for (const key in filter_values) {
        if (this.filter_objects[key] && this.filter_objects[key].callback) continue
        if (Array.isArray(filter_values[key])) value++
      }

      const filter_count = Object.keys(filter_values).filter(item => !this.filter_objects[item].callback).length

      this.table_items.map(item => {
        item.__checked = false
        item.__filtered = 0
        for (const key in filter_values) {
          if (this.filter_objects[key] && this.filter_objects[key].callback) continue
          if (Array.isArray(filter_values[key])) {
            if (filter_values[key].indexOf(item[key]) >= 0) item.__filtered += 1
            if (item.__filtered === filter_count) item.__checked = true
          }
        }
      })

      this.$refs.cdatatable.clean()
      this.$refs.cdatatable.columnFilterEvent('__filtered', value || '', type || 'input')
      this.$set(this, 'filter_values', filter_values)
      this.checked = this.areAllChecked(true)
      this.dataChanged()
    },
    onCleanFilters() {
      this.$refs.cdatatable.clean() // TODO: use this ? it clears the default sorting too
      //this.$refs.cdatatable.tableFilterState = ''
      //this.$refs.cdatatable.columnFilterState = {}
      this.applyFilters({})
    },

    // Utils ---
    setOptions() {
      const length = this.table_items.length
      this.items_per_page_select = [5, ...[10, 20, 50, 100 /*, length > 100 ? length : length + 1*/].filter(value => value <= length)]

      if (this.config.filters) {
        this.config.filters = this.config.filters.map(filter => {
          if (typeof filter === 'string') filter = { key: filter, multiple: true }
          filter.label = filter.label || filter.key
          if (filter.multiple === undefined) filter.multiple = true
          if (filter.default) this.filter_values[filter.key] = get_attribute(this, filter.default) // TODO: check a better way
          //if (filter.select_all) filter.select_all = true
          if (filter.buttonCallback) this.fixed.buttonCallback = filter.buttonCallback
          return filter
        })
        //this.options.filters = this.config.filters.map(item => item.key)
        this.fixed.filters = [...this.config.filters] // TODO: workaround
      }
      this.setExcelFields()

      if (this.options.lastPage) {
        this.options.lastPage = false
        this.$refs.cdatatable.page = Math.ceil(length / this.options.perPage) || 1
      }
    },
    setExcelFields() {
      // TODO: reuse parsed fields
      this.excel_fields = {}
      for (let field in this.fields) {
        field = this.fields[field]
        field = { ...(typeof field === 'string' ? { key: field, default: '' } : { default: '', ...field }) }
        field.label = this.$t(field.label || this._humanize(field.key))
        if (!field._hide && ['options', '__filtered'].indexOf(field.key) === -1) {
          // TODO: !IMPORTANT Improve this (use field object?)
          this.excel_fields[field.label] = { field: field.key }
          if (field.formatter) {
            this.excel_fields[field.label] = this.formatters[field.formatter](field.key) // TODO: fix overwrite object
            this.excel_fields[field.label].formatter = field.formatter
          }
          if (field.formula) this.excel_fields[field.label].formula = field.formula
          if (field.style) this.excel_fields[field.label].style = field.style
          this.excel_fields[field.label].default = field.default
        }
      }
    },
    gotoOption(item, extra, confirm) {
      if (this.config.modal) this.$emit('editItem', item)
      else {
        const route = { path: `${this.options.route || '/' + this.options.url}/${item.id.toString()}${extra || ''}` }
        if (confirm) {
          this.op_confirm = { ...this.op_confirm_duplicate }
          this.op_confirm.show = true
          this.op_confirm.value = item.id
          this.op_confirm.route = route
        } else {
          this.$router.push(route)
        }
      }
    },
    _gotoOption() {
      this.$router.push(this.op_confirm.route)
    },

    // Rows manipulation --
    createItem() {
      console.log({ path: `${this.options.route || '/' + this.options.url}/create` })
      this.$store.dispatch('setCurrentItems', { name: this.options.lname, items: this.table_items })
      this.$router.push({ path: `${this.options.route || '/' + this.options.url}/create` })
    },
    deleteItem(id) {
      this.op_confirm = { ...this.op_confirm_delete }
      this.op_confirm.show = true
      this.op_confirm.value = id
    },
    // TODO: check this (from lists-mixin)
    _deleteItem(item) {
      if (this.$refs.crud_table) this.$refs.crud_table.deleteItem(item.id)
    },
    onModalConfirmed(response) {
      this.op_confirm.show = false
      if (response) {
        if (this.op_confirm.route) this._gotoOption()
        else {
          this.$http
            .delete(this.options.url, { id: this.op_confirm.value })
            .then(() => {
              // TODO: remove from array or grab from DB
              this.showAlert('Successfully deleted row.') // TODO: use ${this.options.name}. ?
              this.getData(false)
            })
            .catch(error => {
              this.showAlert('There was an error.', 'danger')
              console.error(error)
            })
            .finally(() => {
              this.op_confirm.value = false
            })
        }
      }
    },

    // Excel
    /*generateExcelFile(total) {
      let json_data = this.table_items
      if (total) json_data = this.$refs.cdatatable.columnFiltered
    },*/

    // Notify ---
    dataChanged() {
      this.$emit('dataChanged', this.table_items)
    },

    // Listeners ---
    onColumnFilterValue(column_filter) {
      if (column_filter.__filtered === undefined) {
        const filter_values = this.filter_values
        for (const column of Object.keys(column_filter)) {
          if (column_filter[column][0] !== undefined) filter_values[column] = column_filter[column]
          else delete filter_values[column]
        }
        this.applyFilters(filter_values)
      }
    },

    // EMail

    onEmail() {
      this.modalEmail.onEmail(this.modalEmail)
      this.modalEmail.show = true
    },

    onModalEmailUpdated(params) {
      const self = this
      if (params.checked.length) {
        const recipients = { to: [], cc: [], others: [] }
        for (const recipient of params.checked) {
          recipients[recipient.type].push(recipient.email)
        }

        const form_data = {
          file: self.$refs.excel_full.generateEmail(),
          entity: self.modalEmail.entity
        }

        self.$http
          .post(`admin/file_handler/${this.modalEmail.origin || 'generic'}/email?prefix=${params.prefix}&` + new URLSearchParams(recipients).toString(), form_data)
          .then(response => {
            console.log(response)
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            self.resetEventToaster()
          })
      }
      this.modalEmail.show = false
      this.modalEmail.entity = false
    }
  }
}
</script>

<style>
.hide {
  display: none;
}
/* TODO: find another selector? */
.card-body {
  overflow-x: visible;
}
</style>
